/**
 * Created by preference on 2020/11/27
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 9.1.库存盘点列表 */
  get_list_inventoryCheck: params => {
    return API.POST('api/inventoryCheck/list', params)
  },
  /**9.2.库存盘点详情  */
  get_info_inventoryCheck: params => {
    return API.POST('api/inventoryCheck/info', params)
  },
  /** 9.3.库存盘点单创建 */
  get_create_inventoryCheck: params => {
    return API.POST('api/inventoryCheck/create', params)
  },
  /** 9.4.库存盘点单修改 */
  get_update_inventoryCheck: params => {
    return API.POST('api/inventoryCheck/update', params)
  },
  /** 9.5.库存盘点单修改 */
  get_inventoryCheckProductList: params => {
    return API.POST('api/stock/inventoryCheckProductList', params)
  },


  
}